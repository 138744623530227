/*I want to allow people to create their own worlds effortlessly*/

/* this is just a test */
.test {
  background-color: #3b4548;
  font-size: 5vw;
}

body {
  margin: 0;
}

.App, .App-body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-body {
  background-color: #3b4548;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@keyframes navbar-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-section {
  min-height: 100vh;
}

.App-logo {
  pointer-events: none;
  height: 19vw;
  margin: 5rem 0 0 0;
  animation: App-logo-animation 7s;
}

@keyframes App-logo-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Productions {
  color: rgb(193, 213, 220);
  margin: -3.5vw 0px 0vw 0px;
  font-family: serif;
  font-size: 12.7vw;
  font-weight: bold;
}

.Catch-phrase {
  color: rgb(155, 183, 183);
  font-size: 5vw;
  margin: -2vw 0 0 0;
}

.introduction-section {
  min-height: 100vh;
  width: 100%;
  padding: 20rem 0;
  background: linear-gradient(#3b4548 5%, #8ec6d7 30% 70%, #b37070 90% 100%);
  margin: auto;
}

.we-are-a {
  font-size: 5.2vw;
  font-weight: 600;
  padding: 10rem 0 2rem;
  font-family: Kalam, serif;
}

.creating-universal {
  font-size: 5.2vw;
  font-weight: 600;
  padding: 5rem 0 15rem;
  font-family: Kalam, serif;
}

.tool-section {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(#b37070 80%, #9d4ca9);
  margin-top: -0.5rem;
  padding: 10rem 1rem 30rem;
  font-family: Kalam, serif;
}

.using-free {
  font-size: 4.5vw;
  font-weight: 500;
}

.tool-list {
  font-size: 3.5vw;
  list-style-type: none;
  padding-left: 0;
}

.tool-list li {
  position: relative;
  padding-left: 0.1em;
}

.three-tool-list {
  margin-right: -1em;
}

.tool-list li::before {
  content: "•";
  position: absolute;
  left: 4.5em;
  color: black; /* adjust color to match your design */
}

.plan-section {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(#9d4ca9 70%, #edc2c2 90%);
  padding: 20rem 0 40rem;
}

.creating-environments {
  font-size: 5vw;
  font-weight: 600;
  font-family: Kalam, serif;
}

.what-we-do-section {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(#edc2c2 80%, #a0d0ff);
  padding-top: 5rem;
  padding-bottom: 12rem;
}

.what-we-do-title {
  font-size: 3rem;
  padding-bottom: 5rem;
}

.what-we-do-column-formatting {
  padding-bottom: 5rem;
}

.plain-link:link,
.plain-link:visited,
.plain-link:hover,
.plain-link:active {
  all: none;
  text-decoration: none;
  color: black;
}

/* Projects Section */
.projects-section {
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(#a0d0ff, #f0ecf0);
  padding: 5rem;
  padding-bottom: 10vw;
  display: flex;
  flex-direction: column; /* Stack projects vertically */
  align-items: center; /* Center align */
}

.projects-sections {
  padding-top: 5rem;
  width: 100%; /* Full width for each project */
  margin-bottom: 3rem; /* Spacing between projects */
}

#video {
  height: auto;
  width: 100%; /* Full width */
  max-width: 700px; /* Max width to limit video size */
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  opacity: 0.8;
}

.invisible {
  visibility: hidden;
  position: absolute;
}

.biography-section {
  background: linear-gradient(#f3ecf3, #f8f8f8);
  min-height: 100vh;
  width: 100%;
  padding-bottom: 10rem;
}

.biography-title {
  font-size: 2rem;
  font-weight: bold;
  padding: 3rem;
  text-align: center;
}

.thomas-biography-name {
  font-size: 1.5rem;
  font-weight: 525;
  padding: 2rem;
}

.thomas-biography-paragraph {
  padding: 0.5rem;
  font-weight: 350;
  font-size: 1.3rem;
}

.david-biography-name {
  font-size: 1.5rem;
  font-weight: 525;
  padding: 2rem;
}

.david-biography-paragraph {
  font-weight: 350;
  font-size: 1.3rem;
  padding: 0.5rem;
}

.nav-logo {
  width: 10vw;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.167rem;
  position: absolute;
  right: 0;
  padding-right: 0;
}

/* Add a black background color to the top navigation */
.topnav {
  z-index: 3;
  width: 100vw;
  background-color: #3b454869;
  overflow: hidden;
  position: fixed;
  top: 0;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 0.5rem 0rem 0rem 3rem;
  text-decoration: none;
  font-size: 2vw;
  margin: 0 0 1vw -1vw;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

.topnav .icon {
  display: none;
}
